import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCampaignCodes, createCampaignCodes, deleteCampaignCode, resetState } from '../../store/campaign/campaign.slice'
import { Container, Header, Content, Table, Modal, Button, Form, InputNumber, IconButton } from 'rsuite'
import TrashIcon from '@rsuite/icons/Trash';

const { Column, HeaderCell, Cell } = Table;

function CampaignDetail() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { codes, createCodes, deleteCode } = useSelector((state) => state.campaign)
  const [open, setOpen] = React.useState(false)
  const [formValue, setFormValue] = React.useState({
    count: 1,
    length: 8
  })

  useEffect(() => {
    dispatch(getCampaignCodes(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (codes.done) {
      console.log(codes.data?.result?.campaignCodes);
    }
  }, [codes]);

  useEffect(() => {
    if (createCodes.done) {
      dispatch(getCampaignCodes(id))
      dispatch(resetState({ type: "createCodes" }))
      setOpen(false)
    }
  }, [createCodes.done, dispatch, id])

  useEffect(() => {
    if (deleteCode.done) {
      dispatch(getCampaignCodes(id))
      dispatch(resetState({ type: "deleteCode" }))
    }
  }, [deleteCode.done, dispatch, id])

  const handleCreateCodes = () => {
    dispatch(createCampaignCodes({
      campaignId: id,
      count: formValue.count,
      length: formValue.length
    }))
  }

  const handleDelete = (codeId) => {
    dispatch(deleteCampaignCode(codeId))
  }

  return (
    <Container>
      <Header>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}>
          <h4>Kampanya Kodları</h4>
          <Button appearance="primary" onClick={() => setOpen(true)}>
            Kod Oluştur
          </Button>
        </div>
      </Header>

      <Content>
        <Table
          height={window.innerHeight - 200}
          data={codes.data?.result?.campaignCodes || []}
          wordWrap="break-word"
          rowHeight={50}
        >
          <Column width={200} flexGrow={1}>
            <HeaderCell>Kod</HeaderCell>
            <Cell dataKey="code" />
          </Column>

          <Column width={150}>
            <HeaderCell>Maksimum Kullanım</HeaderCell>
            <Cell dataKey="maxUses" align="center" />
          </Column>

          <Column width={150}>
            <HeaderCell>Mevcut Kullanım</HeaderCell>
            <Cell dataKey="currentUses" align="center" />
          </Column>

          <Column width={100}>
            <HeaderCell>Durum</HeaderCell>
            <Cell>
              {rowData => (
                <span style={{ 
                  color: rowData.isActive ? '#4CAF50' : '#F44336',
                  fontWeight: 500
                }}>
                  {rowData.isActive ? 'Aktif' : 'Pasif'}
                </span>
              )}
            </Cell>
          </Column>

          <Column width={150}>
            <HeaderCell>Son Kullanım Tarihi</HeaderCell>
            <Cell>
              {rowData => new Date(rowData.expiryDate).toLocaleDateString('tr-TR')}
            </Cell>
          </Column>

          <Column width={100} fixed="right">
            <HeaderCell>İşlemler</HeaderCell>
            <Cell>
              {rowData => (
                <IconButton 
                  appearance="subtle" 
                  color="red"
                  icon={<TrashIcon />}
                  size="sm"
                  loading={deleteCode.spinner}
                  onClick={() => handleDelete(rowData.id)}
                />
              )}
            </Cell>
          </Column>
        </Table>
      </Content>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          <Modal.Title>Kampanya Kodu Oluştur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form 
            fluid
            formValue={formValue}
            onChange={setFormValue}
          >
            <Form.Group>
              <Form.ControlLabel>Kod Sayısı</Form.ControlLabel>
              <Form.Control 
                name="count" 
                accepter={InputNumber} 
                min={1}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Kod Uzunluğu</Form.ControlLabel>
              <Form.Control 
                name="length" 
                accepter={InputNumber}
                min={6}
                max={16}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpen(false)} appearance="subtle">
            İptal
          </Button>
          <Button 
            loading={createCodes.spinner}
            onClick={handleCreateCodes} 
            appearance="primary"
          >
            Oluştur
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default CampaignDetail
