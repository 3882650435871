import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'campaign';

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        data: null,
    };
}

const initialState = {
    list: createInitialState(),
    create: createInitialState(),
    delete: createInitialState(),
    createCodes: createInitialState(),
    codes: createInitialState(),
    validate: createInitialState(),
    deleteCode: createInitialState(),
};

// Create Campaign
export const createCampaign = createAsyncThunk(
    'campaign/create',
    async (campaignData, { rejectWithValue }) => {
        try {
            const response = await api.post('/super/api/campaign/create', campaignData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Bir hata oluştu');
        }
    }
);

// Get Campaigns
export const getCampaigns = createAsyncThunk(
    'campaign/getAll',
    async ({ pageSize = 10, pageNumber = 1 }, { rejectWithValue }) => {
        try {
            const response = await api.get(`/super/api/campaign/get?pageSize=${pageSize}&pageNumber=${pageNumber}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Bir hata oluştu');
        }
    }
);

// Delete Campaign
export const deleteCampaign = createAsyncThunk(
    'campaign/delete',
    async (campaignId, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/super/api/campaign/delete/${campaignId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Bir hata oluştu');
        }
    }
);

// Create Campaign Codes
export const createCampaignCodes = createAsyncThunk(
    'campaign/createCodes',
    async ({ campaignId, count = 2, length = 7 }, { rejectWithValue }) => {
        try {
            const response = await api.post(`/super/api/campaign/code/create/${campaignId}?count=${count}&length=${length}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Bir hata oluştu');
        }
    }
);

// Get Campaign Codes
export const getCampaignCodes = createAsyncThunk(
    'campaign/getCodes',
    async (campaignId, { rejectWithValue }) => {
        try {
            const response = await api.get(`/super/api/campaign/codes/${campaignId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Bir hata oluştu');
        }
    }
);

// Validate Campaign Code
export const validateCampaignCode = createAsyncThunk(
    'campaign/validateCode',
    async (campaignCode, { rejectWithValue }) => {
        try {
            const response = await api.get(`/api/campaign/code/validate/${campaignCode}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Bir hata oluştu');
        }
    }
);

// Delete Campaign Code
export const deleteCampaignCode = createAsyncThunk(
    'campaign/deleteCode',
    async (campaignCodeId, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/super/api/campaign/code/delete/${campaignCodeId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Bir hata oluştu');
        }
    }
);

const campaignSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetState: (state, action) => {
            const { type } = action.payload;
            state[type] = createInitialState();
        },
    },
    extraReducers: builder => {
        // List reducers
        builder
            .addCase(getCampaigns.pending, state => {
                state.list.spinner = true;
                state.list.error = false;
            })
            .addCase(getCampaigns.fulfilled, (state, action) => {
                state.list.done = true;
                state.list.spinner = false;
                state.list.error = false;
                state.list.data = action.payload;
            })
            .addCase(getCampaigns.rejected, (state, action) => {
                state.list.done = false;
                state.list.spinner = false;
                state.list.error = action.payload;
            })

        // Create reducers
        builder
            .addCase(createCampaign.pending, state => {
                state.create.spinner = true;
                state.create.error = false;
            })
            .addCase(createCampaign.fulfilled, (state, action) => {
                state.create.done = true;
                state.create.spinner = false;
                state.create.error = false;
                state.create.data = action.payload;
            })
            .addCase(createCampaign.rejected, (state, action) => {
                state.create.done = false;
                state.create.spinner = false;
                state.create.error = action.payload;
            })

        // Delete reducers
        builder
            .addCase(deleteCampaign.pending, state => {
                state.delete.spinner = true;
                state.delete.error = false;
            })
            .addCase(deleteCampaign.fulfilled, (state, action) => {
                state.delete.done = true;
                state.delete.spinner = false;
                state.delete.error = false;
                state.delete.data = action.payload;
            })
            .addCase(deleteCampaign.rejected, (state, action) => {
                state.delete.done = false;
                state.delete.spinner = false;
                state.delete.error = action.payload;
            })

        // Campaign Codes reducers
        builder
            .addCase(createCampaignCodes.pending, state => {
                state.createCodes.spinner = true;
                state.createCodes.error = false;
            })
            .addCase(createCampaignCodes.fulfilled, (state, action) => {
                state.createCodes.done = true;
                state.createCodes.spinner = false;
                state.createCodes.error = false;
                state.createCodes.data = action.payload;
            })
            .addCase(createCampaignCodes.rejected, (state, action) => {
                state.createCodes.done = false;
                state.createCodes.spinner = false;
                state.createCodes.error = action.payload;
            })

             // Campaign Codes reducers
        builder
        .addCase(getCampaignCodes.pending, state => {
            state.codes.spinner = true;
            state.codes.error = false;
        })
        .addCase(getCampaignCodes.fulfilled, (state, action) => {
            state.codes.done = true;
            state.codes.spinner = false;
            state.codes.error = false;
            state.codes.data = action.payload;
            })
            .addCase(getCampaignCodes.rejected, (state, action) => {
                state.codes.done = false;
                state.codes.spinner = false;
                state.codes.error = action.payload;
            })

        // Validate Code reducers
        builder
            .addCase(validateCampaignCode.pending, state => {
                state.validate.spinner = true;
                state.validate.error = false;
            })
            .addCase(validateCampaignCode.fulfilled, (state, action) => {
                state.validate.done = true;
                state.validate.spinner = false;
                state.validate.error = false;
                state.validate.data = action.payload;
            })
            .addCase(validateCampaignCode.rejected, (state, action) => {
                state.validate.done = false;
                state.validate.spinner = false;
                state.validate.error = action.payload;
            })

        // Delete Code reducers
        builder
            .addCase(deleteCampaignCode.pending, state => {
                state.deleteCode.spinner = true;
                state.deleteCode.error = false;
            })
            .addCase(deleteCampaignCode.fulfilled, (state, action) => {
                state.deleteCode.done = true;
                state.deleteCode.spinner = false;
                state.deleteCode.error = false;
                state.deleteCode.data = action.payload;
            })
            .addCase(deleteCampaignCode.rejected, (state, action) => {
                state.deleteCode.done = false;
                state.deleteCode.spinner = false;
                state.deleteCode.error = action.payload;
            })
    },
});

export const { resetState } = campaignSlice.actions;
export const campaignReducer = campaignSlice.reducer;
