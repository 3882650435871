import React, { useEffect, useContext } from "react";
import { Sidenav, Nav, Toggle, SelectPicker } from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import ListIcon from "@rsuite/icons/legacy/List";
import { Link, useLocation } from "react-router-dom";
import PageTopIcon from "@rsuite/icons/legacy/PageTop";
import CubeIcon from "@rsuite/icons/legacy/Cube";
import { Outlet, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/verifyCode.slice";
import { ThemeContext } from "../../index"; // index.js'den ThemeContext'i import ediyoruz
import BellIcon from "@rsuite/icons/legacy/Bell";
import FolderIcon from "@rsuite/icons/legacy/Folder";
import { setEnvironment } from '../../store/environment/environment.slice';

function SideNav() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verifyCodeState = useSelector((state) => state.verifyCode);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const environmentOptions = [
    { label: 'Stage', value: 'https://stageflatun.kolonoskapi.com' },
    { label: 'Production', value: 'https://flatun.networklaws.com' },
    { label: 'Local', value: 'http://localhost:7654' }
  ];
  const environment = useSelector((state) => state.environment.apiBase);

  const isActive = (path) => location.pathname === path;

  const HandleLogout = () => {
    dispatch(logout());
    // dispatch(resetCreateCode());
    navigate("/");
    navigate("/login");
  };

  const handleEnvironmentChange = (value) => {
    dispatch(setEnvironment(value));
    window.location.reload();
  };

  useEffect(() => {
    if (!verifyCodeState.isLogin) {
      navigate("/login");
    }
  }, [verifyCodeState.isLogin, navigate]);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Sidenav
        style={{
          width: "240px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Sidenav.Header>
          <div style={{ padding: "20px", textAlign: "center" }}>
            <img
              src="/assets/logo/f-logo.png" // Logo URL'sini buraya ekleyin
              alt="Flatun Logo"
              style={{
                width: "80px",
                height: "80px",
                objectFit: "contain",
                margin: "0 auto 10px",
              }}
            />
            <h3 style={{ margin: 0 }}>Flatun</h3>
          </div>
        </Sidenav.Header>
        <Sidenav.Body style={{ flex: 1 }}>
          <Nav activeKey={location.pathname}>
            <Nav.Item
              as={Link}
              to="/"
              eventKey="/"
              icon={<DashboardIcon />}
              active={isActive("/")}
            >
              Dashboard
            </Nav.Item>

            <Nav.Menu eventKey="2" title="Notification" icon={<BellIcon />}>
              <Nav.Item
                as={Link}
                to="/notification-templates"
                eventKey="/notification-templates"
                active={isActive("/notification-templates")}
              >
                Templates
              </Nav.Item>
              <Nav.Item
                as={Link}
                to="/notification-groups"
                eventKey="/notification-groups"
                active={isActive("/notification-groups")}
              >
                Groups
              </Nav.Item>
              <Nav.Item
                as={Link}
                to="/notification-schedules"
                eventKey="/notification-schedules"
                active={isActive("/notification-schedules")}
              >
                Schedules
              </Nav.Item>
              <Nav.Item
                as={Link}
                to="/notifications"
                eventKey="/notifications"
                active={isActive("/notifications")}
              >
                Notifications
              </Nav.Item>
            </Nav.Menu>
            <Nav.Menu eventKey="3" title="General" icon={<MagicIcon />}>
            <Nav.Item
                as={Link}
                to="/level-of-education"
                eventKey="/level-of-education"
                active={isActive("/level-of-education")}
              >
                Level of Educations
              </Nav.Item>
              <Nav.Item
                as={Link}
                to="/exams"
                eventKey="/exams"
                active={isActive("/exams")}
              >
                Exams
              </Nav.Item>
              <Nav.Item
                as={Link}
                to="/lessons"
                eventKey="/lessons"
                active={isActive("/lessons")}
              >
                Lessons
              </Nav.Item>
              <Nav.Item
                as={Link}
                to="/subjects"
                eventKey="/subjects"
                active={isActive("/subjects")}
              >
                Subjects
              </Nav.Item>
              <Nav.Item
                style={{ pointerEvents: "none", opacity: 0.3 }}
                disabled
                eventKey="3-4"
              >
                Acquisation
              </Nav.Item>
              <Nav.Item as={Link} to="/versions" eventKey="3-5">
                Versions
              </Nav.Item>
              <Nav.Item as={Link} to="/entities" eventKey="3-6">
                Entities
              </Nav.Item>
            </Nav.Menu>
            <Nav.Menu eventKey="4" title="Users" icon={<GearCircleIcon />}>
              <Nav.Item
                eventKey="4-1"
                as={Link}
                to="/users"
                active={isActive("/users")}
              >
                Users
              </Nav.Item>
              {/* <Nav.Item eventKey="4-2">Permissions</Nav.Item>
                <Nav.Item eventKey="4-3">Settings</Nav.Item>
              <Nav.Item eventKey="4-4">Logs</Nav.Item> */}
            </Nav.Menu>

            <Nav.Menu eventKey="5" title="General Feedback" icon={<ListIcon />}>
              <Nav.Item
                as={Link}
                to="/feedback-settings"
                eventKey="5-1"
                active={isActive("/feedback-settings")}
              >
                Feedback Settings
              </Nav.Item>
              <Nav.Item
                as={Link}
                to="/feedbacks"
                eventKey="5-1"
                active={isActive("/feedbacks")}
              >
                Feedbacks
              </Nav.Item>
            </Nav.Menu>

            {/* Updated Packages menu with CubeIcon */}
            <Nav.Menu eventKey="6" title="Packages" icon={<CubeIcon />}>
              <Nav.Item
                as={Link}
                to="/packages"
                eventKey="6-1"
                active={isActive("/packages")}
              >
                Packages
              </Nav.Item>
            </Nav.Menu>
            <Nav.Menu eventKey="7" title="Files" icon={<FolderIcon />}>
              <Nav.Item
                as={Link}
                to="/files"
                eventKey="7-1"
                active={isActive("/files")}
              >
                Files
              </Nav.Item>
            </Nav.Menu>
            <Nav.Menu eventKey="8" title="Campaigns" icon={<CubeIcon />}>
              <Nav.Item
                as={Link}
                to="/campaigns"
                eventKey="8-1"
                active={isActive("/campaigns")}
              >
                Campaigns
              </Nav.Item>
            </Nav.Menu>
            <Nav.Item
              onClick={HandleLogout}
              eventKey="7"
              icon={<PageTopIcon />}
            >
              Logout
            </Nav.Item>
          </Nav>
          <div style={{ padding: "20px" }}>
          <SelectPicker
            data={environmentOptions}
            value={environment}
            onChange={handleEnvironmentChange}
            cleanable={false}
            block
            placeholder="Select Environment"
          />
        </div>
        </Sidenav.Body>
        <div style={{ padding: "20px", textAlign: "start" }}>
          <Toggle
            checked={theme === "dark"}
            onChange={toggleTheme}
            checkedChildren="Dark"
            unCheckedChildren="Light"
          />
        </div>
      
      </Sidenav>
      <div style={{ flexGrow: 1, padding: "20px", overflowY: "auto" }}>
        <Outlet />
      </div>
    </div>
  );
}

export default SideNav;
