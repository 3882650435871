import axios from 'axios';
import { API_BASE } from '../config/env';

const api = axios.create({
    baseURL: localStorage.getItem('apiBase') || API_BASE,
    headers: {
        'Accept-Language': 'tr-tr',
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export const updateBaseURL = (newBaseURL) => {
    api.defaults.baseURL = newBaseURL;
};

export default api;
