import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "updatePackage";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    updatePackage: null,
  };
}

export const updatePackage = createAsyncThunk(
  "updatePackage",
  async (
    {
      id,
      name,
      description,
      price,
      style,
      totalRights,
      scope,
      validityPeriod,
      validityDuration,
      isDefault,
      sortOrder,
      isHidden,
      packageType,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.put("/super/api/package/update", {
        id,
        name,
        description,
        price,
        style,
        totalRights,
        scope,
        validityPeriod,
        validityDuration,
        isDefault,
        sortOrder,
        isHidden,
        packageType,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const updatePackageSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetUpdatePackage: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.updatePackage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePackage.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(updatePackage.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.updatePackage = action.payload;
      })
      .addCase(updatePackage.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetUpdatePackage } = updatePackageSlice.actions;
export const updatePackageReducer = updatePackageSlice.reducer;
