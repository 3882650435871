import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaigns, createCampaign, resetState, deleteCampaign } from '../../store/campaign/campaign.slice';
import { getPackages } from '../../store/package/getPackages.slice';
import { Table, IconButton, Container, Header, Content, ButtonGroup, Button, Modal, Form, DatePicker, InputNumber, SelectPicker } from 'rsuite';
import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";
import { Link } from 'react-router-dom';

const { Column, HeaderCell, Cell } = Table;

function Campaigns() {
    const dispatch = useDispatch();
    const campaignState = useSelector((state) => state.campaign);
    const packageState = useSelector((state) => state.getPackages);
    const [page, setPage] = useState(1);
    const [pageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [createModal, setCreateModal] = useState(false);
    const [newCampaign, setNewCampaign] = useState({
        name: '',
        description: '',
        startDate: null,
        endDate: null,
        packageId: '',
        maxUsageCount: 1,
        codeType: 1,
        usesPerCode: 1,
        placementId: '',
        campaignType: 0
    });
    const [packageOptions, setPackageOptions] = useState([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [campaignToDelete, setCampaignToDelete] = useState(null);

    const campaignTypeOptions = [
        { label: 'Paket Kampanyası', value: 0 },
        { label: 'İndirim Kampanyası', value: 1 }
    ];

    const handleCreateModal = () => {
        setCreateModal(true);
    };

    const handleCloseModal = () => {
        setCreateModal(false);
        setNewCampaign({
            name: '',
            description: '',
            startDate: null,
            endDate: null,
            packageId: '',
            maxUsageCount: 1,
            codeType: 1,
            usesPerCode: 1,
            placementId: '',
            campaignType: 0
        });
    };

    const handleInputChange = (value, name) => {
        setNewCampaign({ ...newCampaign, [name]: value });
    };

    const handleSubmit = () => {
        const formattedCampaign = {
            ...newCampaign,
            startDate: newCampaign.startDate?.toISOString(),
            endDate: newCampaign.endDate?.toISOString(),
            maxUsageCount: parseInt(newCampaign.maxUsageCount, 10),
        };
        
        dispatch(createCampaign(formattedCampaign));
    };

    const handleDeleteCampaign = (campaignId) => {
        setCampaignToDelete(campaignId);
        setDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        if (campaignToDelete) {
            dispatch(deleteCampaign(campaignToDelete));
        }
    };

    const cancelDelete = () => {
        setDeleteModalOpen(false);
        setCampaignToDelete(null);
    };

    useEffect(() => {
        if (campaignState.create.done) {
            handleCloseModal();
            dispatch(getCampaigns({ pageSize, pageNumber: page }));
            dispatch(resetState({ type: 'create' }));
        }
    }, [campaignState.create.done]);

    useEffect(() => {
        dispatch(getCampaigns({ pageSize, pageNumber: page }));
    }, [dispatch, page, pageSize]);

    useEffect(() => {
        if (campaignState?.list?.data?.result?.total) {
            setTotal(campaignState.list.data.result.total);
        }
    }, [campaignState.list.data]);

    useEffect(() => {
        dispatch(getPackages({ pageSize: 100, pageNumber: 1 }));
    }, [dispatch]);

    useEffect(() => {
        if (packageState?.getPackages?.result?.response) {
            const options = packageState.getPackages.result.response
                .map(pkg => ({
                    label: `${pkg.name} (${pkg.totalRights} Hak)`,
                    value: pkg.id,
                    description: pkg.description,
                    price: pkg.price
                }));
            setPackageOptions(options);
        }
    }, [packageState.getPackages]);

    useEffect(() => {
        if (campaignState.delete.done) {
            setDeleteModalOpen(false);
            setCampaignToDelete(null);
            dispatch(getCampaigns({ pageSize, pageNumber: page }));
            dispatch(resetState({ type: 'delete' }));
        }
    }, [campaignState.delete.done]);

    const campaigns = campaignState?.list?.data?.result?.campaigns || [];
    const totalPages = Math.ceil(total / pageSize);

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString('tr-TR');
    };


    return (
        <Container>
            <Header>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 20,
                }}>
                    <h4>Kampanyalar</h4>
                    <IconButton
                        icon={<PlusIcon />}
                        appearance="primary"
                        onClick={handleCreateModal}
                    >
                        Kampanya Ekle
                    </IconButton>
                </div>
            </Header>

            <Content>
                <Table
                    height={window.innerHeight - 200}
                    data={campaigns}
                    loading={campaignState.list.spinner}
                    wordWrap="break-word"
                    rowHeight={50}
                >
                    <Column width={200}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>

                    <Column width={150} flexGrow={1}>
                        <HeaderCell>Kampanya Adı</HeaderCell>
                        <Cell dataKey="name" />
                    </Column>

                    <Column width={200} flexGrow={1}>
                        <HeaderCell>Açıklama</HeaderCell>
                        <Cell dataKey="description" />
                    </Column>

                    <Column width={150}>
                        <HeaderCell>Paket</HeaderCell>
                        <Cell>
                            {rowData => rowData.package?.name || '-'}
                        </Cell>
                    </Column>
                    <Column width={150}>
                        <HeaderCell>Kampanya Tipi</HeaderCell>
                        <Cell>
                            {rowData => rowData.campaignType === 0 ? 'Paket Kampanyası' : 'İndirim Kampanyası'}
                        </Cell>
                    </Column>
                    <Column width={150}>
                        <HeaderCell>Başlangıç Tarihi</HeaderCell>
                        <Cell>
                            {rowData => formatDate(rowData.startDate)}
                        </Cell>
                    </Column>

                    <Column width={150}>
                        <HeaderCell>Bitiş Tarihi</HeaderCell>
                        <Cell>
                            {rowData => formatDate(rowData.endDate)}
                        </Cell>
                    </Column>

                    <Column width={120}>
                        <HeaderCell>Kullanım</HeaderCell>
                        <Cell>
                            {rowData => `${rowData.currentUseCount} / ${rowData.maxUsageCount}`}
                        </Cell>
                    </Column>

                    <Column width={100}>
                        <HeaderCell>Durum</HeaderCell>
                        <Cell>
                            {rowData => rowData.isActive ? 'Aktif' : 'Pasif'}
                        </Cell>
                    </Column>

                    <Column width={100} fixed="right">
                        <HeaderCell>İşlemler</HeaderCell>
                        <Cell>
                            {rowData => (
                                <span style={{ display: 'flex', gap: '5px' }}>
                                    <IconButton
                                        icon={<TrashIcon />}
                                        size="sm"
                                        color="red"
                                        appearance="subtle"
                                        onClick={() => handleDeleteCampaign(rowData.id)}
                                    />
                                    <IconButton
                                        icon={<i className="fa fa-eye"></i>}
                                        size="sm"
                                        color="blue"
                                        appearance="subtle"
                                        as={Link}
                                        to={`/campaign-detail/${rowData.id}`}
                                    />
                                </span>
                            )}
                        </Cell>
                    </Column>
                </Table>

                <div style={{ padding: '20px 0', display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <ButtonGroup>
                        <Button 
                            onClick={() => setPage(page - 1)} 
                            disabled={page === 1}
                        >
                            Önceki
                        </Button>
                        <Button appearance="primary">
                            {page} / {totalPages}
                        </Button>
                        <Button 
                            onClick={() => setPage(page + 1)} 
                            disabled={page >= totalPages}
                        >
                            Sonraki
                        </Button>
                    </ButtonGroup>
                </div>
            </Content>

            <Modal open={createModal} onClose={handleCloseModal} size="md">
                <Modal.Header>
                    <Modal.Title>Yeni Kampanya Oluştur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group>
                            <Form.ControlLabel>Kampanya Adı</Form.ControlLabel>
                            <Form.Control 
                                name="name" 
                                value={newCampaign.name}
                                onChange={(value) => handleInputChange(value, 'name')}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Açıklama</Form.ControlLabel>
                            <Form.Control 
                                name="description" 
                                value={newCampaign.description}
                                onChange={(value) => handleInputChange(value, 'description')}
                                componentClass="textarea"
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Kampanya Tipi</Form.ControlLabel>
                            <SelectPicker 
                                data={campaignTypeOptions}
                                value={newCampaign.campaignType}
                                onChange={(value) => handleInputChange(value, 'campaignType')}
                                style={{ width: '100%' }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Paket</Form.ControlLabel>
                            <SelectPicker 
                                data={packageOptions}
                                value={newCampaign.packageId}
                                onChange={(value) => handleInputChange(value, 'packageId')}
                                style={{ width: '100%' }}
                                loading={packageState.spinner}
                                renderMenuItem={(label, item) => {
                                    return (
                                        <div>
                                            <div><strong>{label}</strong></div>
                                            <div style={{ fontSize: '12px', color: '#666' }}>
                                                {item.description}
                                            </div>
                                            <div style={{ fontSize: '12px', color: '#666' }}>
                                                Fiyat: {item.price} TL
                                            </div>
                                        </div>
                                    );
                                }}
                                renderValue={(value, item) => {
                                    return item ? item.label : 'Paket Seçiniz';
                                }}
                            />
                        </Form.Group>

                        {newCampaign.campaignType === 1 && (
                            <Form.Group>
                                <Form.ControlLabel>Placement ID</Form.ControlLabel>
                                <Form.Control 
                                    name="placementId"
                                    value={newCampaign.placementId}
                                    onChange={(value) => handleInputChange(value, 'placementId')}
                                />
                            </Form.Group>
                        )}

                        <Form.Group>
                            <Form.ControlLabel>Başlangıç Tarihi</Form.ControlLabel>
                            <DatePicker 
                                format="yyyy-MM-dd HH:mm:ss"
                                value={newCampaign.startDate}
                                onChange={(value) => handleInputChange(value, 'startDate')}
                                style={{ width: '100%' }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Bitiş Tarihi</Form.ControlLabel>
                            <DatePicker 
                                format="yyyy-MM-dd HH:mm:ss"
                                value={newCampaign.endDate}
                                onChange={(value) => handleInputChange(value, 'endDate')}
                                style={{ width: '100%' }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Maksimum Kullanım Sayısı</Form.ControlLabel>
                            <InputNumber 
                                value={newCampaign.maxUsageCount}
                                onChange={(value) => handleInputChange(value, 'maxUsageCount')}
                                min={1}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Kod Başına Kullanım</Form.ControlLabel>
                            <InputNumber 
                                value={newCampaign.usesPerCode}
                                onChange={(value) => handleInputChange(value, 'usesPerCode')}
                                min={1}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        onClick={handleSubmit} 
                        appearance="primary"
                        loading={campaignState.create.spinner}
                    >
                        Oluştur
                    </Button>
                    <Button onClick={handleCloseModal} appearance="subtle">
                        İptal
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal open={deleteModalOpen} onClose={cancelDelete} size="xs">
                <Modal.Header>
                    <Modal.Title>Kampanya Silme</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Bu kampanyayı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        onClick={confirmDelete} 
                        appearance="primary" 
                        color="red"
                        loading={campaignState.delete.spinner}
                    >
                        Sil
                    </Button>
                    <Button onClick={cancelDelete} appearance="subtle">
                        İptal
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Campaigns;
