import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "getPackages";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getPackages: null,
  };
}
export const getPackages = createAsyncThunk(
  "getPackages",
  async ({ pageSize, pageNumber }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/super/api/package/get-all?pageSize=${pageSize}&pageNumber=${pageNumber}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

const getPackagesSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetGetPackages: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getPackages = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPackages.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getPackages.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getPackages = action.payload;
      })
      .addCase(getPackages.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetGetPackages } = getPackagesSlice.actions;
export const getPackagesReducer = getPackagesSlice.reducer;
