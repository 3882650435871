import { createSlice } from '@reduxjs/toolkit';
import { API_BASE } from '../../config/env';
import { updateBaseURL } from '../../services/api';

const savedApiBase = localStorage.getItem('apiBase') || 'https://stageflatun.kolonoskapi.com';

const initialState = {
  apiBase: savedApiBase
};

const environmentSlice = createSlice({
  name: 'environment',
  initialState,
  reducers: {
    setEnvironment: (state, action) => {
      state.apiBase = action.payload;
      localStorage.setItem('apiBase', action.payload);
      updateBaseURL(action.payload);
    }
  }
});

export const { setEnvironment } = environmentSlice.actions;
export default environmentSlice.reducer;